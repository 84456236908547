/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from '@emotion/styled';
import TrainingRoomJPG from '@assets/images/training-room.jpg';
import TrainingRoomMobileJPG from '@assets/images/training-room-mobile.jpg';
import { ContainerSmall } from '~/utils/styles/utils';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';
import { useTranslationContext } from '~/pages/trainingsbereich';
// import { useTranslationContext as useTranslationContextEn } from '~/pages/en/traumatic-brain-injury';

const AreaPage: React.FC = () => {
  const t = useTranslationContext();
  // const tEn = useTranslationContextEn();

  return (
    <>
      <HeaderFooterLayout
        desktopHero={TrainingRoomJPG}
        mobileHero={TrainingRoomMobileJPG}
        altHero="Trainingsraum Sportraum Arabellapark"
      >
        <ContainerSmall>
          <div
            dangerouslySetInnerHTML={{
              __html: t('AreaContent1'), // || tEn('NutritionContent1'),
            }}
          />
        </ContainerSmall>
      </HeaderFooterLayout>
    </>
  );
};

const List = styled.div`
  font-size: var(--primary-font-size);
  line-height: 1.5;

  ul {
    list-style: none;
    text-indent: -0.8rem;
    padding-left: 3rem;
  }

  li::before {
    content: '- ';
    padding-right: 0.3rem;
  }
`;

export default AreaPage;
